import React, { FC, useState, useContext } from 'react';
import Cart from '@/assets/icons/cart.svg';
import { StoreContext } from '@/context/storeContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select, QuantityControls } from '@mikafi/ui';
import { GROUND } from '../../constants';
import VariantSelector from '@/components/VariantSelector';
interface Props {
  product: any;
  variant: any;
  setVariant: (variant: any) => void;
  callback?: () => void;
}

const QuantitySelector: FC<Props> = ({
  product,
  variant,
  setVariant,
  callback = () => {},
}) => {
  const {
    options,
    variants,
    variants: [initialVariant],
  } = product;
  const intl = useIntl();

  const { addVariantToCart, loading, client } = useContext(StoreContext);
  const [quantity, setQuantity] = useState(1);
  const [ground, setGround] = useState<string>(
    intl.formatMessage({ id: GROUND[0] }),
  );

  const productVariant =
    // @ts-ignore
    client.product.helpers.variantForOptions(product, variant) || variant;

  const addToCart = async () => {
    await addVariantToCart(productVariant.storefrontId, quantity, [
      {
        key: intl.formatMessage({ id: 'ground' }),
        value: ground,
      },
    ]);
    setVariant({ ...initialVariant });
    setQuantity(1);
    setGround(intl.formatMessage({ id: GROUND[0] }));
    callback();
  };

  return (
    <div>
      <div>
        <div className="mb-3">
          <p className="text-xs my-2">
            <FormattedMessage id="ground" />
          </p>
          <Select
            name="ground"
            onChange={e => setGround(e.target.value)}
            options={GROUND.map(item => ({
              name: intl.formatMessage({ id: item }),
              value: intl.formatMessage({ id: item }),
            }))}
            value={ground}
          />
        </div>
        <VariantSelector
          variants={variants}
          variant={variant}
          setVariant={setVariant}
          options={options}
        />
        <div className="px-12 pt-4">
          <QuantityControls
            handleOnChange={value => setQuantity(value)}
            value={quantity}
          />
        </div>
      </div>

      <button
        disabled={loading}
        className="mt-8 w-full bg-black rounded-full text-white py-5 md:py-4 px-4 flex justify-center items-center flex-row"
        onClick={addToCart}
      >
        <Cart className="fill-white mr-2" />
        <div className="flex flex-col items-start">
          <span className="text-base">
            {loading
              ? `${intl.formatMessage({ id: 'adding' })}...`
              : intl.formatMessage({ id: 'buy' })}
          </span>

          <span className="text-xs opacity-60">
            {new Intl.NumberFormat(`${intl.locale}-CH`, {
              currency: 'CHF',
              style: 'currency',
            }).format(variant.price * quantity)}
          </span>
        </div>
      </button>
    </div>
  );
};

export default QuantitySelector;
