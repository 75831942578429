import { AnimatePresence, motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { ReactNode, FC, useState, CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import Link from '../Link';
import QuantitySelector from '../QuantitySelector';
import Close from '@/assets/icons/close.svg';
import { Button, TasteNote } from '@mikafi/ui';
import { NOTES, ROAST_LEVELS } from '../../constants';
import { camalize } from '@/utils/string';

const compareRoastLevels = (a: string, b: string) => {
  return ROAST_LEVELS[a] - ROAST_LEVELS[b];
};

interface Props {
  children?: ReactNode;
  product: any;
}

const ProductCard: FC<Props> = ({ product }) => {
  const intl = useIntl();
  const {
    variants: [initialVariant],
    priceRangeV2,
  } = product;
  const [variant, setVariant] = useState({ ...initialVariant });
  const [quantitySelector, setQuantitySelector] = useState<boolean>(false);

  const price = new Intl.NumberFormat('de-CH', {
    style: 'currency',
    currency: priceRangeV2.minVariantPrice.currencyCode,
  }).format(priceRangeV2.minVariantPrice.amount);

  const tasteNotes = initialVariant?.tasteNotes?.value
    ? JSON.parse(initialVariant?.tasteNotes.value)
    : {};

  const roastLevels = product?.options
    ?.find((item: any) => item.name === 'Roast level')
    ?.values?.map((item: string) => camalize(item))
    .sort(compareRoastLevels);

  return (
    <div key={product.id} className="relative block p-6">
      <Link to={`/${product.handle}/`} className="no-underline">
        <div className="text-xl md:text-2xl font-medium">{product.title}</div>

        <div className="text-sm mt-1">{product.beans?.[0]?.country}</div>
        <div className="flex items-center justify-center m-12">
          <div className="max-w-[150px] relative">
            {product.featuredImage?.gatsbyImageData && (
              <GatsbyImage
                image={product.featuredImage.gatsbyImageData}
                alt={product.title}
              />
            )}
            {Object.keys(tasteNotes).map((item, i) => {
              const positions: { [key: number]: CSSProperties } = {
                0: { top: '-10%', right: '-20%' },
                1: { bottom: 0, left: '-20%' },
                2: { right: '-10%', bottom: '-5%' },
              };

              return (
                <div className="absolute" style={positions[i]} key={item}>
                  <TasteNote
                    note={item}
                    size={tasteNotes[item]}
                    style={{ backgroundColor: NOTES[item] }}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="my-6 text-center">
          <div className="text-sm text-gray-500">
            {intl.formatMessage({ id: 'choiceOfRoastingLevel' })}
          </div>
          <div className="font-medium text-sm">
            {roastLevels.length > 1 ? (
              <>
                {intl.formatMessage({
                  id: `${roastLevels?.[0]}Roast`,
                })}{' '}
                {intl.formatMessage({ id: 'to' })}{' '}
                {intl.formatMessage({
                  id: `${roastLevels?.[roastLevels.length - 1]}Roast`,
                })}
              </>
            ) : (
              intl.formatMessage({
                id: `${roastLevels?.[0]}Roast`,
              })
            )}
          </div>
        </div>
      </Link>
      <div className="flex items-center justify-between">
        <div className="font-medium text-sm md:text-base">
          {intl.formatMessage({ id: 'from' })} {price}
        </div>
        <Button
          className="flex justify-center items-center"
          onClick={() => setQuantitySelector(true)}
        >
          {intl.formatMessage({ id: 'roastCoffee' })}
        </Button>
      </div>

      <AnimatePresence>
        {quantitySelector && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.4 }}
              exit={{ opacity: 0 }}
              transition={{ ease: [0.45, 0, 0.55, 1], duration: 0.3 }}
              className="z-30 fixed inset-0 bg-black opacity-40"
              onClick={() => setQuantitySelector(false)}
            />
            <motion.div
              initial={{ opacity: 0.9, x: 1000 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0.9, x: 1000 }}
              transition={{
                ease: [0.45, 0, 0.55, 1],
                duration: 0.3,
              }}
              className="z-30 fixed inset-4 md:right-4 md:top-4 md:bottom-4 p-6 bg-white md:left-auto md:w-full max-w-md flex flex-col overflow-auto rounded-4xl"
            >
              <div className="flex flex-row justify-between items-center mb-8">
                <h4>{product?.title}</h4>

                <button onClick={() => setQuantitySelector(false)}>
                  <Close className="h-12 w-12" />
                </button>
              </div>
              <QuantitySelector
                product={product}
                variant={variant}
                setVariant={setVariant}
                callback={() => setQuantitySelector(false)}
              />
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProductCard;
