import React, { FC, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { Select, VariantRadio } from '@mikafi/ui';
import { ROAST_LEVELS } from '../../constants';
import { camalize } from '@/utils/string';

const compareRoastLevels = (a: string, b: string) => {
  return ROAST_LEVELS[camalize(a)] - ROAST_LEVELS[camalize(b)];
};

interface Props {
  variant: any;
  setVariant: (variant: any) => void;
  variants: any;
  options: any;
}

const QuantitySelector: FC<Props> = ({
  options,
  variants,
  variant,
  setVariant,
}) => {
  const intl = useIntl();
  const hasVariants = variants.length > 1;

  if (!hasVariants) return null;

  const [initialVariant] = variants;

  const handleOptionChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const value = event.target.value;

    if (value === '') {
      return;
    }

    const currentOptions = [...variant.selectedOptions];

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    };

    const selectedVariant = variants.find((variant: any) => {
      return (
        JSON.stringify(currentOptions) ===
        JSON.stringify(
          variant ? variant.selectedOptions : initialVariant.selectedOptions,
        )
      );
    });

    setVariant({ ...selectedVariant });
  };

  return (
    <>
      {hasVariants &&
        options.map((option: any, index: number) => {
          if (option.name === 'Roast level') {
            return (
              <div className="mb-3" key={option.id}>
                <p className="text-xs my-2">
                  {intl.formatMessage({ id: camalize(option.name) })}
                </p>
                <Select
                  value={
                    variant.selectedOptions.find(
                      (item: any) => item.name === 'Roast level',
                    )?.value
                  }
                  onChange={event => handleOptionChange(index, event)}
                  options={options
                    .find((item: any) => item.name === 'Roast level')
                    ?.values?.sort(compareRoastLevels)
                    .map((value: any) => ({
                      name: intl.formatMessage({
                        id: `${camalize(value)}Roast`,
                      }),
                      value: value,
                    }))}
                />
              </div>
            );
          }

          return (
            <div key={option.id} className="mb-3">
              <p className="text-xs my-2">
                {intl.formatMessage({ id: camalize(option.name) })}
              </p>
              <div className="flex gap-3">
                {option.values.map((value: string) => {
                  return (
                    <div key={value} className="flex-1">
                      <VariantRadio
                        value={value}
                        name={option.name}
                        defaultChecked={variant.selectedOptions.find(
                          (option: any) => option.value === value,
                        )}
                        onChange={event => handleOptionChange(index, event)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default QuantitySelector;
