import React, { ReactNode, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '@/components/Layout';
import Seo from '@/components/Seo';
import { MOODS } from '../../constants';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import Machine from '@/assets/icons/machine.svg';
import Cup from '@/assets/icons/cup.svg';
import Fresh from '@/assets/icons/fresh.svg';
import ProductCard from '@/components/ProductCard';
import { Card, Accordion } from '@mikafi/ui';
import { StaticImage } from 'gatsby-plugin-image';

type PageContextProps = {
  id: string;
};

type DataProps = {
  allShopifyProduct: any;
  allDatoCmsProduct: any;
  datoCmsOverview: any;
};

const Parterre = ({ data }: PageProps<DataProps, PageContextProps>) => {
  const intl = useIntl();
  const [coffees] = useState(data.allShopifyProduct.nodes);

  return (
    <Layout
      variant="dark"
      showCart={true}
      navChangeColor={false}
      alternatePages={[
        { locale: 'de', value: 'parterre' },
        { locale: 'en', value: 'parterre' },
      ]}
    >
      <Seo title="Parterre" />
      <section className="bg-white">
        <div className="relative pt-32 flex flex-col rounded-b-4xl">
          <div className="text-center mx-auto max-w-screen-md px-4 lg:px-0">
            <StaticImage
              src="./../../assets/images/mikafixparterre.png"
              alt="Parterre"
              objectFit="cover"
              className="aspect-square w-40 object-cover rounded-4xl"
              imgClassName="rounded-4xl overflow-hidden"
            />

            <div className="pt-12 text-6xl md:text-9xl font-medium">
              {intl.formatMessage({ id: 'parterreTitle' })}
            </div>
            <div className="mt-6 font-normal text-xl">
              {intl.formatMessage({ id: 'parterreSubtitle' })}
            </div>
          </div>
          <div className="my-16 space-y-16 md:my-24 md:space-y-0 md:flex md:space-x-16 justify-center mx-6 md:mx-8 lg:mx-16 max-w-screen-2xl 2xl:mx-auto 2xl:px-16">
            <Card
              icon={true}
              image={<Machine className="w-28 h-28" />}
              title={intl.formatMessage({ id: 'parterreFact1Title' })}
              subtitle={intl.formatMessage({ id: 'parterreFact1Subtitle' })}
              showButton={false}
            ></Card>
            <Card
              icon={true}
              image={<Fresh className="w-28 h-28" />}
              title={intl.formatMessage({ id: 'parterreFact2Title' })}
              subtitle={intl.formatMessage({ id: 'parterreFact2Subtitle' })}
              showButton={false}
            ></Card>
            <Card
              icon={true}
              image={<Cup className="w-28 h-28" />}
              title={intl.formatMessage({ id: 'parterreFact3Title' })}
              subtitle={intl.formatMessage({ id: 'parterreFact3Subtitle' })}
              showButton={false}
            ></Card>
          </div>
        </div>
      </section>
      <section className="bg-white pt-8  lg:pt-4">
        <div className="pt-12  max-w-screen-2xl 2xl:mx-auto 2xl:px-16 pb-10 sm:pb-0">
          <div className="md:flex md:space-x-12 my-8 mx-6 ">
            <div className="md:w-3/12 mb-8">
              <h3>{intl.formatMessage({ id: 'parterreRoastsTitle' })}</h3>
            </div>
            <div className="md:flex-1">
              <h2 className="mt-2 sm:mt-0 font-normal mb-6 text-xl">
                {intl.formatMessage({ id: 'parterreRoastsSubTitle' })}
              </h2>
            </div>
          </div>
          <div className="lg:flex gap-5">
            <div className="flex lg:flex-row lg:flex-wrap gap-5 overflow-x-auto px-4 w-full">
              {coffees.map((product: any) => {
                const productContent = data.allDatoCmsProduct.nodes.find(
                  (item: any) => item.productId === product.shopifyId,
                );
                return (
                  <div
                    key={product.id}
                    className={classNames(
                      'flex-none rounded-4xl bg-opacity-20 border w-11/12 lg:w-[31%] border-opacity-30 hover:bg-opacity-30 hover:border-opacity-40',
                      {
                        [MOODS[product.mood.value]?.bg]: product.mood.value,
                        [MOODS[product.mood.value]?.border]: product.mood.value,
                      },
                    )}
                  >
                    <ProductCard
                      product={{
                        ...product,
                        ...productContent,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white pt-8  lg:pt-16">
        <div className="pt-12 mx-6 md:mx-8 lg:mx-16 max-w-screen-2xl 2xl:mx-auto 2xl:px-16 pb-10 sm:pb-0">
          <div className="md:flex md:space-x-12 my-8">
            <div className="md:w-3/12 mb-4">
              <h3>Popup</h3>
            </div>
          </div>
          <StaticImage
            src="./../../assets/images/parterre.png"
            alt="Parterre"
            objectFit="cover"
            className="aspect-[10/12] lg:aspect-[10/6] object-cover rounded-4xl"
            imgClassName="rounded-4xl overflow-hidden"
          />
        </div>
      </section>
      <section className="bg-white">
        <div className="pt-12 md:pt-20 mx-6 md:mx-8 lg:mx-16 max-w-screen-2xl 2xl:mx-auto 2xl:px-16 pb-10 sm:pb-32">
          <div className="md:flex md:space-x-12 mt-20 sm:mt-24">
            <div className="md:w-4/12">
              <h3 className="mt-8 mb-8 md:mb-0 md:mt-12">FAQ</h3>
            </div>
            <div className="flex-1">
              <Accordion
                title={intl.formatMessage({ id: 'parterreFaq1Title' })}
                text={intl.formatMessage({ id: 'parterreFaq1Text' })}
              />
              <Accordion
                title={intl.formatMessage({ id: 'parterreFaq2Title' })}
                text={intl.formatMessage({ id: 'parterreFaq2Text' })}
              />
              <Accordion
                title={intl.formatMessage({ id: 'parterreFaq3Title' })}
                text={intl.formatMessage({ id: 'parterreFaq3Text' })}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($locale: String) {
    datoCmsOverview(locale: { eq: $locale }) {
      title
      description
    }
    allDatoCmsProduct(filter: { locale: { eq: $locale } }) {
      nodes {
        productId
        description
        tags {
          title
          text
        }
        beans {
          country
          location
          name
          estate
          image {
            gatsbyImageData
          }
          altitude
          description
          species
          variety
          preparation
          harvestTime
          certificates
          traceability {
            title
            price
            summary
          }
        }
      }
    }
    allShopifyProduct(
      filter: {
        collections: {
          elemMatch: { id: { eq: "535517d5-475f-512a-bd50-05b548e1c68e" } }
        }
      }
    ) {
      nodes {
        id
        shopifyId
        title
        description
        handle
        featuredImage {
          gatsbyImageData(height: 350)
        }
        collections {
          id
        }
        mood: metafield(key: "mood", namespace: "specs") {
          key
          value
        }
        suitedFor: metafield(key: "suitedFor", namespace: "specs") {
          key
          value
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        options {
          name
          values
          id: shopifyId
        }
        variants {
          availableForSale
          storefrontId
          title
          price
          selectedOptions {
            name
            value
          }
          acidity: metafield(key: "acidity", namespace: "specs") {
            namespace
            value
          }
          bitterness: metafield(key: "bitterness", namespace: "specs") {
            namespace
            value
          }
          body: metafield(key: "body", namespace: "specs") {
            namespace
            value
          }
          tasteNotes: metafield(key: "taste_notes", namespace: "specs") {
            namespace
            value
          }
          roastLevelDescription: metafield(
            key: "roastLevelDescription"
            namespace: "specs"
          ) {
            namespace
            value
          }
        }
      }
    }
  }
`;

export default Parterre;
